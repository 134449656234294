<template>
    <div>
        <Feedv2Component/>
    </div>
</template>

<script>
    // @ is an alias to /src
    import Feedv2Component from "../components/Feedv2Component";

    export default {
        name: 'Feedv2',
        components: {
            Feedv2Component
        }
    }
</script>
