
    import { Component, Vue } from 'vue-property-decorator';
    import VModal from 'vue-js-modal';
    import Settings from "@/services/Settings";
    import VueResource from 'vue-resource';

    Vue.use(VModal);
    Vue.use(VueResource);

    @Component
    export default class Feedv1Component extends Vue {
      private uid: string;
      private host = '';
      private projectSettings = {};
      private settingsLoaded = false;
      private projectViewSettings = {};
      private loader = false;
      private mentions = [];
      private limit = 8;
      private actualPage = 1;
      private maxPage = 0;
      private selectedMention = {};

      created() {
        const settings = new Settings();
        this.host = settings.getSetting('apiHost');
        this.uid = this.$route.params.uid;
        this.getHeightListener();
        this.getProjectSettings();
        this.mentions = [];
        this.getData(1, this.limit);
      }
      show (mention) {
        this.selectedMention = mention;
        this.$modal.show('post-preview');
      };
      hide () {
        this.$modal.hide('post-preview');
      };
      getProjectSettings() {
        this.$http.get(this.host + '/apis/' + this.uid + '/project', {

        }).then((response: any) => {
          this.projectSettings = response.body;
          this.projectViewSettings = response.body.credentials.feed;
          this.settingsLoaded = true;
        });
      }

      getData(page: number, limit: number) {
        if (this.loader) return;
        this.loader = true;
        this.$http.get(this.host + '/apis/' + this.uid + '/feed', {
          params: {
            page: page.toString(),
            limit: limit.toString(),
            sort_by: 'time desc',
            // only_photo: 1
          }
        }).then((response: any) => {
          this.mentions.push(...response.body._embedded.items);
          this.actualPage = response.body.page;
          this.maxPage = response.body.pages;
          this.limit = response.body.limit;
          this.loader = false;
          setTimeout(() => {
            // console.log('Sending postMessage', document.getElementById('feed').clientHeight);
            window.parent.postMessage(JSON.stringify({
              "docHeight": document.getElementById('feed').clientHeight
            }), "*");
          }, 200);
        }, response => {
          this.loader = false;
        });
      }

      getHeightListener() {
        function getMsg (evt: any) {
          if (evt.data === 'getHeight') {
            window.parent.postMessage(JSON.stringify({
              "docHeight": document.getElementById('feed').clientHeight
            }), "*");
          }
        }
        window.addEventListener('message', getMsg);
      }


    }
