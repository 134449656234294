var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"posts-wrapper",style:({
    backgroundColor: _vm.projectViewSettings.backgroundColor
}),attrs:{"id":"feed"}},_vm._l((_vm.mentions),function(mention,index){return _c('div',{key:index,staticClass:"post",class:{'post-special': index + 1 === _vm.logoPosition}},[(index + 1 !== _vm.logoPosition)?_c('a',{staticClass:"image",style:({
            backgroundImage: 'url(' + mention.content_picture + ')'
      }),attrs:{"href":mention.link,"target":"_blank"}}):_vm._e(),(index + 1 === _vm.logoPosition && _vm.projectViewSettings.graphic !== undefined)?_c('a',{staticClass:"logo",style:({
                  backgroundImage: 'url(' + (_vm.projectViewSettings.graphic.startsWith('data:') ? _vm.projectViewSettings.graphic : _vm.imageStorage + _vm.projectViewSettings.graphic) + ')'
                }),attrs:{"href":"https://facebook.com","target":"_blank"}}):_vm._e(),(index + 1 === _vm.logoPosition)?_c('a',{staticClass:"content",style:({
                  backgroundColor: _vm.projectViewSettings.titleBoxColor,

                }),attrs:{"href":"https://facebook.com","target":"_blank"}},[_c('h3',{style:({
                  color: _vm.projectViewSettings.descriptionColor
                })},[_vm._v(_vm._s(_vm.projectViewSettings.description))]),_c('h1',{style:({
                        color: _vm.projectViewSettings.titleColor,
                        fontSize: _vm.projectViewSettings.fontSize
                    })},[_vm._v(_vm._s(_vm.projectViewSettings.title))])]):_vm._e()])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }