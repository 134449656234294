<template>
    <div>
        <Mapv2Component/>
    </div>
</template>

<script>
    // @ is an alias to /src
    import Mapv2Component from '@/components/Mapv2Component.vue'

    export default {
        name: 'Mapv2',
        components: {
            Mapv2Component
        }
    }
</script>
