<template>
    <div>
        <NoFeedComponent/>
    </div>
</template>

<script>
    // @ is an alias to /src
    import NoFeedComponent from '@/components/NoFeedComponent.vue'

    export default {
        name: 'NoFeed',
        components: {
            NoFeedComponent
        }
    }
</script>
