<template>
    <div>
        <Mapv3Component/>
    </div>
</template>

<script>
    // @ is an alias to /src
    import Mapv3Component from '@/components/Mapv3Component.vue'

    export default {
        name: 'Mapv3',
        components: {
            Mapv3Component
        }
    }
</script>
