
    import { Component, Vue } from 'vue-property-decorator';
    import VueResource from 'vue-resource'
    import Settings from './../services/Settings';

    Vue.use(VueResource);

    @Component
    export default class Feedv2Component extends Vue {
        private uid: string;
        private host = '';
        private imageStorage = '';
        private mentions = [];
        private actualPage = 1;
        private maxPage = 0;
        private limit = 20;
        private loader = false;
        private settingsLoaded = false;
        private projectSettings = {};
        private projectViewSettings = {};
        private logoPosition: number = 5;

        created() {
            const settings = new Settings();
            this.host = settings.getSetting('apiHost');
            this.imageStorage = settings.getSetting('storagePath');
            this.uid = this.$route.params.uid;
            this.getHeightListener();
            this.calculateMainSpacePosition();
            this.getProjectSettings();
            this.mentions = [];
            this.getData(1, this.limit);
            this.resizeEvent();
        }

        getProjectSettings() {
            this.$http.get(this.host + '/apis/' + this.uid + '/project', {

            }).then((response: any) => {
                this.projectSettings = response.body;
                this.projectViewSettings = response.body.credentials.feed;
                this.settingsLoaded = true;
            });
        }

        getData(page: number, limit: number) {
            if (this.loader) return;
            this.loader = true;
            this.$http.get(this.host + '/apis/' + this.uid + '/feed', {
                params: {
                    page: page.toString(),
                    limit: limit.toString(),
                    sort_by: 'time desc',
                    only_photo: 1
                }
            }).then((response: any) => {
                this.mentions.push(...response.body._embedded.items);
                this.actualPage = response.body.page;
                this.maxPage = response.body.pages;
                this.limit = response.body.limit;
                this.loader = false;
                setTimeout(() => {
                  // console.log('Sending postMessage', document.getElementById('feed').clientHeight);
                  window.parent.postMessage(JSON.stringify({
                    "docHeight": document.getElementById('feed').clientHeight
                  }), "*");
                }, 200);
            }, response => {
                this.loader = false;
            });
        }

      getHeightListener() {
        function getMsg (evt: any) {
          if (evt.data === 'getHeight') {
            window.parent.postMessage(JSON.stringify({
              "docHeight": document.getElementById('feed').clientHeight
            }), "*");
          }
        }
        window.addEventListener('message', getMsg);
      }

        resizeEvent() {
            const that = this;
            window.addEventListener('resize', (event) => {
                that.calculateMainSpacePosition();
            });
        }

        calculateMainSpacePosition() {
            const width = window.innerWidth;
            if (width < 420) {
                this.logoPosition = 2;
            } else if (width < 800) {
                this.logoPosition = 3;
            } else if (width < 1200) {
                this.logoPosition = 5;
            } else if (width < 1600) {
                this.logoPosition = 7;
            } else if (width < 2000) {
                this.logoPosition = 9;
            } else if (width < 2200) {
                this.logoPosition = 11;
            } else if (width < 2600) {
                this.logoPosition = 13;
            } else if (width < 2800) {
                this.logoPosition = 15;
            } else {
                this.logoPosition = 17;

            }
        }
    }
