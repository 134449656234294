<template>
    <div>
        <Feedv1Component/>
    </div>
</template>

<script>
    // @ is an alias to /src
    import Feedv1Component from '@/components/Feedv1Component.vue'

    export default {
        name: 'Feedv1',
        components: {
            Feedv1Component
        }
    }
</script>
