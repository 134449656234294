<template>
    <div>
        <Feedv4Component/>
    </div>
</template>

<script>
    // @ is an alias to /src
    import Feedv4Component from "../components/Feedv4Component";

    export default {
        name: 'Feedv4',
        components: {
            Feedv4Component
        }
    }
</script>
