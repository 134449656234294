<template>
    <div>
        <Feedv5Component/>
    </div>
</template>

<script>
    // @ is an alias to /src
    import Feedv5Component from "../components/Feedv5Component";

    export default {
        name: 'Feedv5',
        components: {
            Feedv5Component
        }
    }
</script>
