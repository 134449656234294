<template>
    <div>
        <Feedv3Component/>
    </div>
</template>

<script>
    // @ is an alias to /src
    import Feedv3Component from "../components/Feedv3Component";

    export default {
        name: 'Feedv3',
        components: {
            Feedv3Component
        }
    }
</script>
